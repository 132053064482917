.input-phone-number input:focus {
  height: 50px;
  outline: none !important;
  border: 2px solid #1976d2 !important;
  box-shadow: none !important;
  border-radius: 5px;
}

.input-phone-number input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #acacac !important;
}
.input-phone-number input[type="tel" i] {
  padding: 1px 10px;
  font-size: 15px;
}